import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useGlobal } from '../../../hooks/global';
import api from '../../../services/api';
import { ContainerOptions, Span } from './style';
import { lightFormat } from 'date-fns/esm';
import PaginationCustom, { PaginationRefProps } from './../../../components/PaginationCustom';
import { AiOutlineEye } from 'react-icons/ai';
import { CircularProgress } from '@material-ui/core';
import { selectionInterface } from '../../../interfaces/selectionInterface';
import { useHistory, useParams } from 'react-router';
import { HiOutlineArrowNarrowLeft } from 'react-icons/hi';
import { UserProps } from '../../../interfaces/userInterface';
import { MaskCPF, MaskPhone } from '../../../services/maskServices';
import RelatoriosRoot from '../../../components/Modals/RelatoriosRoot';

export const SelecaoEspecifica: React.FC = () => {
    const [selecoes, setSelecoes] = useState<selectionInterface[]>([] as selectionInterface[])
    const [candidatos, setCandidatos] = useState<UserProps[]>([] as UserProps[])
    const [selecao, setSelecao] = useState<selectionInterface>({} as selectionInterface)
    const paginationRef = useRef<PaginationRefProps>(null)
    const [loadingSelection, setLoadingSelection] = useState(true)
    const [atualPage, setAtualPage] = useState(1)
    const [numberOfPages, setNumberOfPages] = useState(0)
    const { selectionId }: any = useParams()
    const [openModal, setOpenModal] = useState(false)
    const [candidatoSelected, setCandidatoSelected] = useState<UserProps>({} as UserProps)

    const { push } = useHistory()

    const changePage = useCallback((newPage) => {
        if (atualPage !== newPage) setAtualPage(newPage)
    }, [atualPage])

    const { setAtualPageGlobal } = useGlobal()
    const [hover, setHover] = useState<number>(-1)

    const SearchSelection = useCallback(async () => {
        setLoadingSelection(true)
        setTimeout(async () => {
            const resu = await api.get(`/root/selections/${selectionId}?limit=11&&page=${atualPage}`)
            setSelecao(resu.data.selection)
            setCandidatos(resu.data.candidates)
            setNumberOfPages(Math.ceil(resu.data.count / 11))
            console.log(resu.data)
            setLoadingSelection(false)
        }, 200)
    }, [atualPage])

    useEffect(() => {
        setAtualPageGlobal("/root/selecoes")
    }, [])

    useEffect(() => {
        SearchSelection()
    }, [atualPage])

    const clickViewCandidate = useCallback((candidate) => {
        setCandidatoSelected(candidate)
        setOpenModal(true)
    }, [])

    return (
        <div style={{ display: "flex", height: "100%", justifyContent: "space-between", margin: 20, marginTop: 0, flexDirection: "column", marginBottom: 20 }}>
            <div style={{ display: "flex", position: "relative", justifyContent: "center", alignItems: "center", width: "100%", fontFamily: "Gotham-Medium", color: "#FFF", fontSize: 26, marginBottom: 20 }}>
                <div onClick={() => push("/root/selecoes")} style={{ display: "flex", position: "absolute", fontSize: 16, left: 0, flexDirection: "row", color: "#FFF", cursor: "pointer" }}>
                    <HiOutlineArrowNarrowLeft style={{ marginRight: 10 }} />
                    Voltar
                </div>
                Candidatos da Seleção "{selecao.name}"
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', height: "100%", justifyContent: 'space-between' }}>
                {!loadingSelection && candidatos.length > 0 &&
                    <table style={{ width: "100%", borderRadius: 5, borderCollapse: "collapse", textAlign: "center" }}>
                        <thead>
                            <tr style={{ color: "#FFF" }}>
                                <th style={{ background: "#302c2c", width: "25%", borderTopLeftRadius: 10, padding: 10 }}>Nome</th>
                                <th style={{ background: "#302c2c", width: "20%" }}>Email</th>
                                <th style={{ background: "#302c2c", width: "13.333333%" }}>CPF</th>
                                <th style={{ background: "#302c2c", width: "9%" }}>Setor</th>
                                <th style={{ background: "#302c2c", width: "13.333333%" }}>Telefone</th>
                                <th style={{ background: "#302c2c", width: "13.333333%" }}>Gênero</th>
                                <th style={{ background: "#302c2c", width: "10%", borderTopRightRadius: 10 }}>Ações</th>
                            </tr>
                        </thead>
                        <tbody style={{ background: "#FFF" }}>
                            {candidatos.map((candidato, index) => (
                                <tr key={candidato.id}>
                                    <td style={{ borderBottom: "1px solid #C4C4C4", padding: 10 }}>{candidato.name}</td>
                                    <td style={{ borderBottom: "1px solid #C4C4C4" }}>{candidato.email}</td>
                                    <td style={{ borderBottom: "1px solid #C4C4C4" }}>{MaskCPF(candidato.cpf)}</td>
                                    <td style={{ borderBottom: "1px solid #C4C4C4" }}>Ainda nada</td>
                                    <td style={{ borderBottom: "1px solid #C4C4C4" }}>{MaskPhone(candidato.phone)}</td>
                                    <td style={{ borderBottom: "1px solid #C4C4C4" }}>{candidato.sex}</td>
                                    <td style={{ borderBottom: "1px solid #C4C4C4" }}>
                                        <ContainerOptions >
                                            <AiOutlineEye onMouseEnter={() => setHover(index)} onMouseLeave={() => setHover(-1)} onClick={() => clickViewCandidate(candidato)} style={{ minWidth: "1.25rem", minHeight: "1.25rem", cursor: "pointer" }} color="#101A36" />
                                            {hover === index &&
                                                <Span>
                                                    Visualizar
                                                </Span>
                                            }
                                        </ContainerOptions>
                                    </td>

                                </tr>
                            ))}

                        </tbody>
                    </table>
                }
                {!loadingSelection && candidatos.length === 0 &&
                    <div style={{ fontStyle: "italic", fontFamily: "Gotham-Medium", color: "#DDDDDD", display: "flex", justifyContent: 'center', alignItems: "center", height: '100%' }}>Esta seleção não possui nenhum candidato</div>
                }
                {loadingSelection &&
                    <div style={{ display: "flex", height: "100%", justifyContent: "center", alignItems: "center" }}>
                        <CircularProgress color="secondary" />
                    </div>
                }
                {openModal &&
                    <RelatoriosRoot
                        data={candidatoSelected}
                        open={openModal}
                        onClose={() => { setOpenModal(false) }}
                    />
                }
                <div style={{ display: "flex", justifyContent: "center", alignItems: "flex-end", padding: 10 }}>
                    <PaginationCustom ref={paginationRef} pages={numberOfPages} disable={loadingSelection} onChange={changePage} />
                </div>
            </div>

        </div>
    )
}