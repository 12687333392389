import React, { useCallback, useEffect, useState } from "react";
import { FaLinkedin, FaUser, FaRegFilePdf } from "react-icons/fa";
import { useHistory, useParams } from "react-router";
import InputPerfil from "../../../components/InputPerfil";
import { UserProps } from "../../../interfaces/userInterface";
import api from "../../../services/api";
import { MaskCPF, MaskPhone } from "../../../services/maskServices";
import { selectionInterface } from "../../../interfaces/selectionInterface";
import { useGlobal } from "../../../hooks/global";
import { ConEmailTel } from "./style";
import DecisionModal from "../../../components/ConfirmModal/decisionModal";

export const PerfilCandidatoS: React.FC = () => {
  const [modalOpenRefuse, setModalRefuseOpen] = useState(false);
  const [modalOpenAccept, setModalAcceptOpen] = useState(false);
  const { requestId, selectionId }: any = useParams();
  const [candidate, setCandidate] = useState<UserProps>({} as UserProps);
  const { push } = useHistory();
  const [itemId, setItemId] = useState(null);
  const [selection, setSelection] = useState<selectionInterface>(
    {} as selectionInterface
  );

  const { setAtualPageGlobal, languagePage } = useGlobal();

  const searchInfos = useCallback(async () => {
    const resu = await api.get(`/company/resquest/${requestId}`);
    setCandidate(resu.data.candidate);
    setSelection(resu.data.selection);
  }, []);

  useEffect(() => {
    setAtualPageGlobal("/company/selecoes");
    searchInfos();
  }, []);

  const accept = useCallback(async (requestId) => {
    alert("Usuário aceito, por favor solicite que ele recarregue a página !");
    const resu = await api.post(`/company/request/accept/${requestId}`);
    push(`/company/solicitacoes/${selectionId}`);
  }, []);

  const decline = useCallback(async (requestId) => {
    const resu = await api.post(`/company/request/decline/${requestId}`);
    push(`/company/solicitacoes/${selectionId}`);
  }, []);


  const toggleRefuseModal = () => setModalRefuseOpen(!modalOpenRefuse);
  const handleActionRefuse = (id) => {
    setItemId(id);
    toggleRefuseModal();
  };

  const confirmDecline = (itemId) => {
    decline(itemId);
    toggleRefuseModal();
  };

  const toggleAcceptModal = () => setModalAcceptOpen(!modalOpenAccept);
  const handleActionAccept = (id) => {
    setItemId(id);
    toggleAcceptModal();
  };

  const confirmAccept = (itemId) => {
    decline(itemId);
    toggleAcceptModal();
  };
  return (
    <ConEmailTel
      style={{
        display: "flex",
        flexDirection: "column",
        height: "calc(100% - 40px)",
        margin: 20,
        fontFamily: "Gotham-Medium",
      }}
    >
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          color: "#64666b",
          fontSize: 26,
          textAlign: "center"
        }}
      >
        <div
          onClick={() => push(`/company/solicitacoes/${selectionId}`)}
          style={{
            fontSize: '2.15vh',
            color: '#6b6b6b',
            cursor: 'pointer',
            width: '100%',
            maxWidth: '200px',
            minWidth: '70px',
            borderRadius: '50px',
            border: '1px solid #6b6b6b',
            flexDirection: 'row',
            justifyContent: 'center',
            textAlign: 'center',
            padding: '8px',
            position: 'absolute',
            left: '15px',
            top: '20px',
            transform: 'translateY(-50%)',
          }}
        >

          {languagePage === "PT" ? "Voltar" : "Back"}
        </div>
        <div style={{
          width: "70%",

          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          margin: 5
        }}>
          {languagePage === "PT"
            ? "Perfil do Candidato para a seleção"
            : "Candidate Profile for selection"}{" "}
          "{selection.name}"
        </div>

      </div>
      <div style={{ display: "flex", margin: "auto", width: "100%", maxWidth: "1300px", minWidth: "200px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            background: "#fff",
            borderRadius: 10,
            padding: 20,

            margin: 20,
            width: "100%", maxWidth: "1300px", minWidth: "200px"
          }}
        >
          <div className="flexWOne" style={{ display: "flex", flexDirection: "row", }}>
            <div
              style={{
                display: "flex",
                borderRadius: 10,
                justifyContent: "center",
                alignItems: "center",
                background: "#FFF",
                boxShadow: " 0.125rem 0.125rem 0.5rem rgba(0,0,0,1)",
                minWidth: 166,
                minHeight: 166,
                maxWidth: 166,
                maxHeight: 166,
                margin: 10
              }}
            >
              {candidate.avatar && (
                <img style={{ height: 140 }} src={candidate.avatar} alt="Imagem" />
              )}
              {!candidate.avatar && <FaUser color="#000" size={140} />}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                maxWidth: 1100,
                minWidth: "200px",

              }}
            >
              <InputPerfil
                styleInput={{
                  fontFamily: "Gotham-Medium",
                  color: "#000",
                  background: "#FFF",
                  fontSize: 16,
                }}
                styleContainer={{
                  background: "#FFF",
                  margin: 20,
                  marginTop: 0,
                }}
                placeholder="Nome da Empresa"
                value={candidate.name}
                disabled
                label="nome"
              />
              <div className="flexW" style={{ display: "flex", flexDirection: "row", }}>
                <InputPerfil

                  styleInput={{
                    fontFamily: "Gotham-Medium",
                    color: "#000",
                    background: "#FFF",
                    fontSize: 16,

                  }}
                  styleContainer={{
                    background: "#FFF",
                    margin: "auto",
                    marginBottom: 20,



                    width: "90%",
                    maxWidth: "500px",
                    minWidth: "150px"


                  }}
                  placeholder="Email"
                  label="email"
                  value={candidate.email}
                  disabled

                />
                <InputPerfil

                  styleInput={{
                    fontFamily: "Gotham-Medium",
                    color: "#000",
                    background: "#FFF",
                    fontSize: 16,



                  }}
                  styleContainer={{
                    background: "#FFF",
                    margin: "auto",
                    marginBottom: 20,

                    width: "90%",
                    maxWidth: "500px",
                    minWidth: "150px"
                  }}
                  placeholder="Telefone"
                  label="telefone"
                  value={MaskPhone(
                    candidate.phone === undefined ? "" : candidate.phone
                  )}
                  disabled
                />
              </div>
              <div className="flexW" style={{ display: "flex", flexDirection: "row", }}>
                <InputPerfil

                  styleInput={{
                    fontFamily: "Gotham-Medium",
                    color: "#000",
                    background: "#FFF",
                    fontSize: 16,

                  }}
                  styleContainer={{
                    background: "#FFF",


                    margin: "auto",
                    marginBottom: 20,

                    width: "90%",
                    maxWidth: "500px",
                    minWidth: "150px"
                  }}
                  placeholder="CNPJ"
                  label="cnpj"
                  value={MaskCPF(
                    candidate.cpf === null ? "" : candidate.cpf === undefined ? "" : candidate.cpf
                  )}
                  disabled
                />
                <InputPerfil


                  styleInput={{
                    fontFamily: "Gotham-Medium",
                    color: "#000",
                    background: "#FFF",
                    fontSize: 16,
                  }}
                  styleContainer={{
                    background: "#FFF",
                    margin: "auto",
                    marginBottom: 20,


                    width: "90%",
                    maxWidth: "500px",
                    minWidth: "150px"
                  }}
                  placeholder="Gênero"
                  label="gênero"
                  value={candidate.sex}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="media-277"
            style={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "row",
              justifyContent:
                !!candidate.linkedin || !!candidate.curriculum
                  ? "space-between"
                  : "flex-end",
              fontFamily: "Gotham-Medium",
              color: "#FFF",

              width: "100%",
              maxWidth: 1250,
              minWidth: "200px",
            }}
          >
            <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
              {!!candidate.linkedin && (
                <a
                  target="_blank"
                  href={candidate.linkedin}
                  style={{
                    display: "flex",
                    textDecoration: "none",
                    color: "#FFF",
                    cursor: "pointer",
                    minWidth: 146,
                    justifyContent: "center",
                    alignItems: "center",



                    borderRadius: 5,
                    background: "#0A66C2",
                    boxShadow: "0.125rem 0.125rem 0.5rem rgba(0,0,0,1)",
                    height: 50,
                    width: 160,
                    marginRight: 10,
                    padding: 10,
                    margin: 10,
                  }}
                  rel="noreferrer"
                >
                  <FaLinkedin style={{ marginRight: 5 }} />
                  LinkedIn
                </a>
              )}
              {!!candidate.curriculum && (
                <a
                  target="_blank"
                  href={candidate.curriculum}
                  style={{
                    display: "flex",
                    textDecoration: "none",
                    color: "#FFF",
                    cursor: "pointer",
                    minWidth: 146,
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 5,
                    background: "#ff2c2c",
                    boxShadow: "0.125rem 0.125rem 0.5rem rgba(0,0,0,1)",
                    height: 50,
                    width: 160,
                    marginRight: 10,
                    padding: 10,
                    margin: 10,
                  }}
                  rel="noreferrer"
                >
                  <FaRegFilePdf style={{ marginRight: 5 }} />
                  {languagePage === "PT" ? "Currículo" : "Professional resume"}
                </a>
              )}
            </div>
            <div style={{ display: "flex", flexWrap: "wrap", textAlign: "center", justifyContent: "center" }}>
              <div
                onClick={() => handleActionRefuse(requestId)}
                style={{
                  cursor: "pointer",
                  height: 50,
                  width: 160,
                  marginRight: 10,
                  padding: 10,
                  margin: 10,

                  borderRadius: 5,
                  background: "#8D171A",
                  boxShadow: "0.125rem 0.125rem 0.5rem rgba(0,0,0,1)",
                }}
              >
                {languagePage === "PT" ? "Recusar" : "Refuse"}
              </div>
              <div
                onClick={() => handleActionAccept(requestId)}
                style={{
                  cursor: "pointer",
                  padding: 10,
                  height: 50,
                  width: 160,
                  margin: 10,
                  borderRadius: 5,
                  background: "#009848",
                  boxShadow: "0.125rem 0.125rem 0.5rem rgba(0,0,0,1)",
                }}
              >
                {languagePage === "PT" ? "Aceitar" : "Accept"}
              </div>
            </div>
          </div>
        </div>
      </div>
      <DecisionModal
            isOpen={modalOpenRefuse}
            toggle={toggleRefuseModal}
            onConfirm={() => confirmDecline(itemId)}
            languagePage={languagePage}
            message={languagePage === "PT" ? "Tem certeza que deseja recusar este candidato?" : "Are you sure you want to reject this candidate?"}
          />
          <DecisionModal
            isOpen={modalOpenAccept}
            toggle={toggleAcceptModal}
            onConfirm={() => confirmAccept(itemId)}
            languagePage={languagePage}
            message={languagePage === "PT" ? "Tem certeza que deseja aceitar este candidato?" : "Are you sure you want to accept this candidate?"}
          />
    </ConEmailTel>
    
  );
};
